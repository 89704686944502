import React from "react";
import "./about-us.scss";

function AboutUs() {
    return (
        <>
            <head>
                <title>About us - Growthgensolutions</title>
                <meta
                    name="description"
                    content="We are a proudly South African Level 1 (135% B-BEE Procurement Recognition Level) company. Our Teams are driven with energy, passion to succeed and finding exactly what you are looking for. Quality benchmarks are
                        what we pride ourselves with."
                />
            </head>
            <div>
                <div className="container">
                    <div className="new-img-container  itemImg flex-shrink-0">
                        <img className="card-img-top img-fluid" height={700} src={`/img/FullColor_1280x1024_72dpi.png`} alt="Growthgensolutions" />
                    </div>
                    <div className="category-header my-3">Who we are</div>
                    <p>
                        We are a proudly South African Level 1 (135% B-BEE Procurement Recognition Level) company. Our Teams are driven with energy, passion to succeed and finding exactly what you are looking for. Quality benchmarks are
                        what we pride ourselves with.
                    </p>
                    <div className="category-header my-3">What we do</div>
                    <p>We are a preferential procurement partner who supplies laptops, servers, PPE items and your basic needs products that you require.</p>
                    <div className="category-header my-3">Why we do it</div>
                    <p>
                        Our passion lies in innovation and technology to introduce premium products that will enhance people’s lives. Our mission is to make sure our market remains competitive and by doing so, GGS will continue distributing
                        premium products at competitive pricing so that all our customers can afford quality products without breaking the bank.
                    </p>
                    <div className="category-header my-3" id="contact-detail">
                        Contact details
                    </div>
                    <p>Need to give us a call?</p>
                    <ul>
                        <li>Elna - 082 560 3054</li>
                        <li>Zianca - 071 687 8124</li>
                    </ul>
                    <p>Need to send us an email?</p>
                    <ul>
                        <li>elna@ggsol.co.za</li>
                        <li>zianca@ggsol.co.za</li>
                    </ul>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </>
    );
}

export default AboutUs;
