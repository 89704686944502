import React, { useState } from "react";
import "./add-delivery-address.scss";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ShippingAddresses } from "types/shippingAddresses";
import { toast } from "react-toastify";
import { UserType } from "types/user";
import api from "api";

interface props {
    onSave: any;
    onCancel: any;
    user: UserType;
}

function AddDeliveryAddress({ onSave, onCancel, user }: props) {
    const [address, setAddress] = useState<ShippingAddresses>({
        address: "",
        addressType: "Business",
        city: "",
        country: "",
        customerId: user.id,
        id: 0,
        isDeleted: false,
        postCode: "",
        recipientContactNumber: "",
        recipientName: "",
        suburb: "",
    } as ShippingAddresses);

    const chagneVaue = (prop: "address" | "addressType" | "city" | "country" | "customerId" | "postCode" | "recipientContactNumber" | "recipientName" | "suburb", value: any) => {
        var newState = { ...address };
        if (prop === "address") {
            newState.address = value;
        }
        if (prop === "addressType") {
            newState.addressType = value;
        }
        if (prop === "city") {
            newState.city = value;
        }
        if (prop === "country") {
            newState.country = value;
        }
        if (prop === "customerId") {
            newState.customerId = value;
        }
        if (prop === "postCode") {
            newState.postCode = value;
        }
        if (prop === "recipientContactNumber") {
            newState.recipientContactNumber = value;
        }
        if (prop === "recipientName") {
            newState.recipientName = value;
        }
        if (prop === "suburb") {
            newState.suburb = value;
        }
        setAddress(newState);
    };

    const validateNewAddress = (callback: any) => {
        if (address.address.trim() === "") {
            toast.warning("Please insert a valid street name and number:");
            return;
        }
        if (address.recipientName.trim() === "") {
            toast.warning("Please insert a valid recipient name:");
            return;
        }
        if (address.recipientContactNumber.trim() === "") {
            toast.warning("Please insert a valid recipient contact number:");
            return;
        }
        if (address.suburb.trim() === "") {
            toast.warning("Please insert a valid recipient suburb:");
            return;
        }
        if (address.city.trim() === "") {
            toast.warning("Please insert a valid city:");
            return;
        }
        if (address.country.trim() === "") {
            toast.warning("Please insert a valid country:");
            return;
        }
        if (address.country.trim() === "") {
            toast.warning("Please insert a valid post code:");
            return;
        }
        callback();
    };

    const cancel = () => {
        setAddress({
            address: "",
            addressType: "Business",
            city: "",
            country: "",
            customerId: user.id,
            id: 0,
            isDeleted: false,
            postCode: "",
            recipientContactNumber: "",
            recipientName: "",
            suburb: "",
        });
        onCancel();
    };

    const save = async () => {
        var data = await api.customerHippingAddress.insert(address);
        if (data) {
            toast.success("Adress added");
        }
        onSave();
    };

    return (
        <div className="adress-form">
            <div className="container">
                <div className="card mx-5 mt-3">
                    <div className="login-header px-3 py-1">
                        <div className="my-2">Add Delivery Addresses</div>
                    </div>
                    <div className="login-body p-5 pt-3">
                        <div className="form-item">
                            <Form.Label>Addess type</Form.Label>
                            <div className="ad-type-grid">
                                <div className="form-check">
                                    <input className="form-check-input" checked={address.addressType === "Business"} type="checkbox" id="Business" onChange={(e) => chagneVaue("addressType", "Business")} />
                                    <label htmlFor="Business">Business</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" checked={address.addressType === "Residencial"} type="checkbox" id="Residencial" onChange={(e) => chagneVaue("addressType", "Residencial")} />
                                    <label htmlFor="Residencial">Residencial</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-item">
                            <Form.Label>Recipient name:</Form.Label>
                            <Form.Control required type="text" placeholder="Recipient name:" value={address.recipientName} onChange={(e) => chagneVaue("recipientName", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <Form.Label>Recipient contact number:</Form.Label>
                            <Form.Control required type="text" placeholder="000 000 0000" value={address.recipientContactNumber} onChange={(e) => chagneVaue("recipientContactNumber", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <Form.Label>Street:</Form.Label>
                            <Form.Control required type="text" placeholder="10 Streetname" value={address.address} onChange={(e) => chagneVaue("address", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <Form.Label>Suburb:</Form.Label>
                            <Form.Control required type="text" placeholder="Suburb" value={address.suburb} onChange={(e) => chagneVaue("suburb", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <Form.Label>City:</Form.Label>
                            <Form.Control required type="text" placeholder="City" value={address.city} onChange={(e) => chagneVaue("city", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <Form.Label>Country:</Form.Label>
                            <Form.Control required type="text" placeholder="South africa" value={address.country} onChange={(e) => chagneVaue("country", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <Form.Label>Post Code:</Form.Label>
                            <Form.Control required type="text" placeholder="Post Code" value={address.postCode} onChange={(e) => chagneVaue("postCode", e.currentTarget.value)} />
                        </div>
                        <div className="ad-btns">
                            <button className="btn btn-primary mx-3" onClick={() => save()}>
                                Save and select
                            </button>
                            <button className="btn btn-secondary" onClick={() => cancel()}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddDeliveryAddress;
