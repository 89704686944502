import api from "api";
import React, { useEffect, useState } from "react";
import { ProductSearchRequest, ProductType } from "types";
import { useParams } from "react-router-dom";
import ProductShort from "components/product-short";
import { useIntersectionObserver } from "hooks/IntersectionObserverInit";
import { useRef } from "react";

function Search() {
    const params = useParams<any>();
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [take, setTake] = useState<number>(30);
    const [skip, setSkip] = useState<number>(0);
    const [products, setProducts] = useState<ProductType[]>([]);
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {});
    const isVisible = !!entry?.isIntersecting;
    const searchString = !params.searchString ? "" : params.searchString;
    const getProductBySearch = async () => {
        var data = await api.products.search({
            SearchString: params.searchString,
            Skip: skip,
            Take: take,
        } as ProductSearchRequest);
        console.log(data);
        setIsloading(false);
        setProducts(data);
    };

    const getMore = async () => {
        var newState = [...products];
        var newSkip = skip + take;
        var newItems = await api.products.search({
            SearchString: params.searchString,
            Skip: skip,
            Take: take,
        } as ProductSearchRequest);
        console.log(newItems);
        setSkip(newSkip);
        newState = newState.concat(newItems);
        setProducts(newState);
    };

    useEffect(() => {
        if (isLoading === false) {
            if (isVisible === true) {
                getMore();
            }
        }
    }, [isVisible]);

    useEffect(() => {
        getProductBySearch();
    }, [params.searchString]);

    return (
        <>
            <head>
                <title>{`Search - ${decodeURIComponent(searchString)}`}</title>
                <meta name="description" content={`Search - ${decodeURIComponent(searchString)}`} />
            </head>
            <div className="category">
                <div className="category-header my-3"></div>
                <div className="products">
                    {products.length === 0 && <p className="no-items-list mx-2">No items found.</p>}
                    {products.map((p) => (
                        <ProductShort product={p} />
                    ))}
                    <div ref={ref}>
                        <i></i>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Search;
