// import Route from "components/route";
import AboutUs from "pages/about-us";
import Cart from "pages/cart";
import Category from "pages/category";
import Home from "pages/home";
import Login from "pages/login/login";
import Orders from "pages/orders/orders";
import Product from "pages/product/product";
import Register from "pages/register";
import ResetPassword from "pages/reset-password/reset-password";
import Search from "pages/search";
import React, { useEffect } from "react";
// import { HashRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MyNav from "components/nav/nav";
import SideNav from "components/side-nav";
import { useDispatch } from "react-redux";
import api from "api";
import { TokenUserId } from "types";
import { actions } from "redux/reducers";

function App() {
    if (localStorage.getItem("cart") === null) {
        localStorage.cart = [];
    }
    const dispatch = useDispatch();
    //const user = useSelector((state: RootState) => state.user);
    const getUser = async () => {
        if (localStorage.AccessToken) {
            const user = await api.user.getUser({
                Token: localStorage.AccessToken,
                UserId: localStorage.UserId,
            } as TokenUserId);
            dispatch(actions.user.set(user));
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <div className="App">
            <ToastContainer />
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <Home />
                                    </div>
                                </div>
                            </>
                        }
                    />
                    <Route
                        path="/category/:CategoryDescription/:categoryId"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <Category />
                                    </div>
                                </div>
                            </>
                        }
                    />
                    <Route
                        path="/cart"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <Cart />
                                    </div>
                                </div>
                            </>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <>
                                <Login />
                            </>
                        }
                    />
                    <Route
                        path="/register"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <Register />
                                    </div>
                                </div>
                            </>
                        }
                    />
                    <Route
                        path="/orders"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <Orders />
                                    </div>
                                </div>
                            </>
                        }
                    />

                    <Route
                        path="/search/:searchString"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <Search />
                                    </div>
                                </div>
                            </>
                        }
                    />
                    <Route
                        path="/reset-password"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <ResetPassword />
                                    </div>
                                </div>
                            </>
                        }
                    />
                    <Route
                        path="/product/:productCode/:productDescription"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <Product />
                                    </div>
                                </div>
                            </>
                        }
                    />
                    <Route
                        path="/about-us"
                        element={
                            <>
                                <MyNav />
                                <div className="side-nav-content-grid">
                                    <SideNav />
                                    <div className="content">
                                        <AboutUs />
                                    </div>
                                </div>
                            </>
                        }
                    />
                </Routes>
            </BrowserRouter>
            {/* <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/category/:CategoryDescription/:categoryId" component={Category} exact />
                <Route path="/cart" component={Cart} exact />
                <Route path="/login" component={Login} exact />
                <Route path="/register" component={Register} exact />
                <Route path="/orders" component={Orders} exact />
                <Route path="/search/:searchString" component={Search} exact />
                <Route path="/reset-password" component={ResetPassword} exact />
                <Route path="/product/:productCode/:productDescription" component={Product} exact />
                <Route path="/about-us" component={AboutUs} exact />
            </Switch> */}
        </div>
    );
}

export default App;
