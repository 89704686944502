import React, { useEffect } from "react";
import { ProductType } from "types";
import { RootState, CartType } from "types";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers";
import "./product-short.scss";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

interface props {
    product: ProductType;
}

function ProductShort({ product }: props) {
    const dispatch = useDispatch();
    const cart = useSelector((state: RootState) => state.cart);

    const hashText = (string: string, charLimit: number) => {
        return string.substring(0, charLimit) + "...";
    };

    const removeItemById = () => {
        var newState = [] as ProductType[];
        const index = cart.findIndex((item) => item.id === product.id);
        cart.map((x: ProductType) => {
            newState.push(x);
        });

        newState.splice(index, 1);
        dispatch(actions.cart.set(newState));
    };

    const openProduct = () => {
        console.log("open");
    };

    const addToCart = () => {
        var newCart = [...cart] as ProductType[];
        newCart.push(product);
        dispatch(actions.cart.set(newCart));
    };

    function countOccurrencesById() {
        return cart.reduce((count, item) => (item.id === product.id ? count + 1 : count), 0);
    }

    return (
        <div className="product">
            <div className="card">
                <div className="img-container  itemImg flex-shrink-0">
                    <img
                        className="card-img-top img-fluid"
                        height={200}
                        src={`https://www.growthgensolutions.co.za/wwwroot/${product.imgFullLocation}`}
                        alt={product.longDescription}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/img/img_notFound.jpg";
                        }}
                    />
                </div>
                <div className="card-body">
                    <p className="card-text">
                        <b>{product.productCode}</b> - {hashText(product.longDescription, 90)}
                    </p>
                    <div className="row">
                        <div className="col-6 price">R{product.price}</div>
                        <div className="col-6">
                            <div className="add-to-cart">
                                {countOccurrencesById() === 0 && (
                                    <div onClick={addToCart} className="add-to-cart-text">
                                        Add to cart
                                    </div>
                                )}
                                {countOccurrencesById() !== 0 && (
                                    <>
                                        <div className="add-remove-icons">
                                            <i className="fa-lg fa fa-minus-circle hand" onClick={removeItemById}></i>
                                            <span className="mx-1 no-select">{countOccurrencesById()}</span>
                                            <i className="fa-lg fa fa-plus-circle hand" onClick={addToCart}></i>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="more-details text-center mt-2">
                            <Link to={`/product/${product.productCode}/${product.longDescription.trim().replace(/\s+/g, "_")}`}>
                                <div className="btn btn-outline-info w-100 my-btn">More details</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductShort;
