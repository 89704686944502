import { API_URI, apiGet, apiPost } from "api";
import { ShippingAddresses } from "types/shippingAddresses";

const customerHippingAddress = {
    getByUser: async (UserId: number) => {
        const data = await apiGet(`${API_URI}/api/customershippingaddress/byId/${UserId}`);
        return data;
    },
    delete: async (id: number) => {
        const data = await apiGet(`${API_URI}/api/customershippingaddress/delete/${id}`);
        return data;
    },
    insert: async (address: ShippingAddresses) => {
        const data = await apiPost(`${API_URI}/api/customershippingaddress/insert`, address);
        return data;
    },
};

export default customerHippingAddress;
