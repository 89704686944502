import { API_URI, apiGet, apiPost, apiPostPlain } from "api";
import { LoginRequest, TokenUserId } from "types";
import { UserType } from "types/user";

const user = {
    getUser: async (loginRequest: TokenUserId) => {
        const data = await apiPost(`${API_URI}/api/auth/user/token/site`, loginRequest);
        return data;
    },
    login: async (loginRequest: LoginRequest) => {
        const data = await apiPost(`${API_URI}/api/auth/login`, loginRequest);
        return data;
    },
    retsetPassword: async (email: string) => {
        const data = await apiGet(`${API_URI}/api/auth/reset/password?email=${email}`);
        return data;
    },
    registerUser: async (user: UserType) => {
        console.log(user);

        const data = await apiPost(`${API_URI}/api/auth/register`, {
            Id: 0,
            Name: user.name,
            ContackNumber: user.contackNumber,
            Email: user.email,
            Password: user.password,
            DateCreated: new Date().toJSON().split(".")[0],
            IsConfirmedCustomer: false,
            IsDeleted: false,
            IdCustomerTier: 1,
            IsAdminUser: false,
        } as any);
        return data;
    },
};

export default user;
