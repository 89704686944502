import { userActions } from "./user";
import UserReducer from "./user";
import CartReducer, { cartActions } from "./cart";
import { combineReducers } from "redux";

const actions = {
    user: userActions,
    cart: cartActions,
};

const rootReducer = combineReducers({
    user: UserReducer,
    cart: CartReducer,
});

export { rootReducer, actions };
