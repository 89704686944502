import ProductCartItem from "components/product-cart-item";
import React, { useEffect, useState } from "react";
import { OrderType, ProductType, SaveOrderType } from "types";
import "./cart.scss";
import { Link } from "react-router-dom";
import { actions } from "redux/reducers";
import { RootState, CartType } from "types";
import { useDispatch, useSelector } from "react-redux";
import api from "api";
import { ShippingAddresses } from "types/shippingAddresses";
import { Form } from "react-bootstrap";
import AddDeliveryAddress from "components/add-delivery-address";
import { OrderProductDetails } from "types/order-product-details";
import { toast } from "react-toastify";

function Cart() {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const cart = useSelector((state: RootState) => state.cart);
    const [products, setProducts] = useState<CartType[]>([]);
    const [shippingAdresses, setShippingAdresses] = useState<ShippingAddresses[]>([] as ShippingAddresses[]);
    const [sSelected, setSSelected] = useState<number>(0);
    const [showAddAddress, setShowAddAddress] = useState<boolean>(false);

    const getProduct = () => {
        var newList = [] as CartType[];
        cart.map((x: ProductType) => {
            const index = newList.findIndex((item) => item.product.id === x.id);
            if (index === -1) {
                newList.push({
                    amount: cart.reduce((count, item) => (item.id === x.id ? count + 1 : count), 0),
                    product: x,
                });
            }
        });
        setProducts(newList);
    };

    const getOverAllPrice = () => {
        var total = 0;
        cart.map((x: ProductType) => {
            total += x.price;
        });
        // total + 75;
        return (Math.round((total + 75) * 100) / 100).toFixed(2);
    };

    const getShippingAdresses = async () => {
        var data = await api.customerHippingAddress.getByUser(user.id);
        setShippingAdresses(data);
        setShowAddAddress(false);
    };

    const removeItemById = (id: number) => {
        var newState = [] as ShippingAddresses[];
        const index = shippingAdresses.findIndex((item) => item.id === id);
        shippingAdresses.map((x) => {
            newState.push(x);
        });

        newState.splice(index, 1);
        setShippingAdresses(newState);
    };

    const submitOrder = async () => {
        var saveOrder = {} as SaveOrderType;
        var order = {
            CustomerId: parseInt(localStorage.UserId),
            Id: 0,
            IdOrderStatus: 1,
            IdShippingAddress: sSelected,
            IsDeleted: false,
            OrderDate: new Date().toISOString().split("T")[0] + "T00:00",
        } as OrderType;
        saveOrder.Order = order;
        var saveProducts = [] as OrderProductDetails[];
        for (var product of products) {
            saveProducts.push({
                CustomerTier: "",
                DiscountPrecentage: 0,
                Id: 0,
                IdOrder: 0,
                IdProduct: product.product.id,
                IsDeleted: false,
                ProductPrice: product.product.price,
                ProductQuantity: product.amount,
                ShippingPrice: 0,
                Vat: 0,
            });
        }
        saveOrder.OrderProductDetails = saveProducts;
        var data = await api.order.insert(saveOrder);
        if (data.message) {
            toast.success("Order created");
            window.open("https://dash.growthgensolutions.co.za/Invoice?orderId=" + data.message);
            dispatch(actions.cart.set([]));
        }
        console.log(data.message);
    };

    useEffect(() => {
        getProduct();
    }, [cart]);

    useEffect(() => {
        if (user !== null) {
            getShippingAdresses();
        }
    }, [user]);

    useEffect(() => {
        if (shippingAdresses.length > 0) {
            setSSelected(shippingAdresses[0].id);
        }
    }, [shippingAdresses]);

    return (
        <>
            <head>
                <title>Cart - Growthgensolutions</title>
                <meta name="description" content="Your cart" />
            </head>
            <div className="container-fluid">
                <div className="category-header my-3">Your cart</div>
                {products.length == 0 && (
                    <p className="no-items-list mx-2">
                        No items in the cart. <b>Go shopping!</b>
                    </p>
                )}
                <div className="cart-body">
                    {products.map((p) => (
                        <ProductCartItem productCount={p} />
                    ))}
                </div>
                <div className="delivery-addresses">
                    {products.length > 0 && <div className="category-header my-3">Delivery Addresses</div>}
                    {products.length > 0 &&
                        shippingAdresses.map((s) => (
                            <div className="row ad-item container-fluid hand" onClick={() => setSSelected(s.id)}>
                                <div className="col-1 check-box">
                                    <div className="form-check">
                                        <input className="form-check-input" checked={sSelected === s.id} type="checkbox" id="flexCheckDefault" />
                                    </div>
                                </div>
                                <div className="col-10 ad-body">
                                    <p>
                                        {s.recipientName} - {s.addressType}
                                    </p>
                                    <p>{s.address}</p>
                                    <p>{s.city}</p>
                                    <p>{s.recipientContactNumber}</p>
                                </div>
                                <div className="col-1 ad-actions">
                                    <button className="btn btn-danger btn-sm" onClick={() => removeItemById(s.id)}>
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))}

                    {products.length > 0 && showAddAddress === false && (
                        <div className="add-address my-2">
                            <button className="btn btn-primary" onClick={() => setShowAddAddress(true)}>
                                Add new
                            </button>
                        </div>
                    )}
                    {showAddAddress === true && <AddDeliveryAddress onSave={getShippingAdresses} onCancel={() => setShowAddAddress(false)} user={user} />}
                </div>

                <div className="cart-footer">
                    <div className="category-header">Cart Summary</div>
                    <div className="amout-price-spilt">
                        <div>Shipping Price</div>
                        <div>R 75</div>
                    </div>
                    <div className="amout-price-spilt">
                        <div>Total: {cart.length} (item/s)</div>
                        <div>R {getOverAllPrice()}</div>
                    </div>
                    <div className="amout-price-spilt">
                        <div></div>
                        <div>
                            {user === null && (
                                <Link to="/login">
                                    <button className="btn btn-primary">Login</button>
                                </Link>
                            )}
                            {user && sSelected === 0 && <a className="btn btn-danger">Please add a shipping address</a>}
                            {sSelected !== 0 && user !== null && (
                                <a className="btn btn-success" onClick={() => submitOrder()}>
                                    Proceed to Checkout
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cart;
