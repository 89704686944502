import React, { useEffect, useState } from "react";
import { Button, Container, Form, Nav, NavDropdown, Navbar, Spinner } from "react-bootstrap";
import "./nav.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { actions } from "redux/reducers";

function MyNav() {
    const dispatch = useDispatch();
    const location = useLocation();
    let navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const cart = useSelector((state: RootState) => state.cart);
    const [search, setSeatch] = useState<string>(!localStorage.search ? "" : localStorage.search);

    const logout = () => {
        dispatch(actions.user.set(null));
        localStorage.removeItem("AccessToken");
        localStorage.removeItem("UserId");
    };

    const doSearch = () => {
        if (search.trim() !== "") {
            var searchValue = encodeURIComponent(search);
            navigate("/search/" + searchValue);
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        if (search !== localStorage.search) {
            localStorage.search = search;
        }
    }, [search]);

    return (
        <Navbar id="my-nav" expand="lg" className="nav bg-body-tertiary">
            <Container fluid>
                <Navbar.Brand href="/">
                    <div className="icon-name">
                        <img src="/img/Logo 2.png" height="40" alt="" />
                        <a className="brand-name colort-purple">GROWTHGEN SOLUTIONS</a>
                    </div>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll>
                        <Nav.Link>
                            <Link to={"/"}>Home</Link>
                        </Nav.Link>
                        {user !== null && (
                            <NavDropdown title={user.name} id="navbarScrollingDropdown">
                                <NavDropdown.Item>
                                    <Link to={"/orders"}> My orders</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                    <Link to={"/reset-password"}>Reset password</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={logout}>Log out</NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {user === null && (
                            <NavDropdown title="User" id="navbarScrollingDropdown">
                                <NavDropdown.Item>
                                    <Link to={"/login"}>
                                        <div>Log in </div>
                                    </Link>
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                    </Nav>
                    <Form className="d-flex nav-search">
                        <Form.Control type="search" placeholder="Search" className="me-2" aria-label="Search" value={search} onChange={(e) => setSeatch(e.currentTarget.value)} />
                        <Button variant="primary" onClick={doSearch}>
                            <i className="fas fa-search"></i>
                        </Button>
                    </Form>
                    <Form className="mx-2">
                        <Link to="/cart">
                            <Button variant="primary">
                                <i className="fas fa-shopping-cart "></i>
                                {cart !== null && <span className="mx-1">{cart.length}</span>}
                                {cart === null && <span>0</span>}
                            </Button>
                        </Link>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MyNav;
