import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: null,
    reducers: {
        set: (state: any, action: { payload: any }) => {
            state = action.payload;
            return state;
        },
    },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
