import { API_URI, apiGet, apiPost } from "api";
import { SaveOrderType } from "types";
import { ShippingAddresses } from "types/shippingAddresses";

const order = {
    insert: async (order: SaveOrderType) => {
        const data = await apiPost(`${API_URI}/api/order/insert`, order);
        return data;
    },
    getOrders: async (customerId: number) => {
        const data = await apiGet(`${API_URI}/api/ordercustomer/customer?customerId=${customerId}`);
        return data;
    },
};

export default order;
