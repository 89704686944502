import React, { useEffect, useState } from "react";
import "./side-nav.scss";
import api from "api";
import { CategoryType } from "types";
import { Link } from "react-router-dom";

function SideNav() {
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [selected, setSelected] = useState<number>(0);

    const getCategories = async () => {
        var data = await api.category.getAll();
        setCategories(data);
    };

    const selectCategory = (id: number) => {};

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <div className="side-nav">
            <div className="side-nav p-1">
                <div className="header">Search by Category</div>
                {categories.map((x) => (
                    <Link to={`/category/${x.description.replace(/ /g, "_")}/${x.id}`}>
                        <div className={selected === x.id ? "item selected" : "item"} onClick={() => selectCategory(x.id)}>
                            {x.description}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default SideNav;
