import { API_URI, apiGet, apiPost } from "api";
import { ProductSearchRequest } from "types";

const products = {
    getByCategories: async () => {
        const data = await apiGet(`${API_URI}/api/product/categories`);
        return data;
    },
    getByCategory: async (categoryId: number, take: number, skip: number) => {
        const data = await apiGet(`${API_URI}/api/product/category/${categoryId}/${take}/${skip}`);
        return data;
    },
    search: async (productSearchRequest: ProductSearchRequest) => {
        const data = await apiPost(`${API_URI}/api/product/search`, productSearchRequest);
        return data;
    },
    getByProductCode: async (code: string) => {
        const data = await apiGet(`${API_URI}/api/product/code/${code}`);
        return data;
    },
    getRelatedProducts: async (code: string) => {
        const data = await apiGet(`${API_URI}/api/product/related/${code}`);
        return data;
    },
};

export default products;
