import { API_URI, apiGet } from "api";

const category = {
    getAll: async () => {
        const data = await apiGet(`${API_URI}/api/product/categories`);
        return data;
    },
};

export default category;
