import api from "api";
import React, { useEffect, useState } from "react";
import { ProductType } from "types";
import { useParams } from "react-router-dom";
import ProductShort from "components/product-short";
import { useIntersectionObserver } from "hooks/IntersectionObserverInit";
import { useRef } from "react";

function Category() {
    const params = useParams<any>();
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [take, setTake] = useState<number>(30);
    const [skip, setSkip] = useState<number>(0);
    const [products, setProducts] = useState<ProductType[]>([]);
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {});
    const isVisible = !!entry?.isIntersecting;
    const categoryId = !params.categoryId ? 0 : Number(params.categoryId);
    const CategoryDescription = !params.CategoryDescription ? "" : params.CategoryDescription;

    const getProductByCategory = async () => {
        var data = await api.products.getByCategory(categoryId, take, skip);
        setIsloading(false);
        setProducts(data);
    };

    const getMore = async () => {
        var newState = [...products];
        var newSkip = skip + take;
        var newItems = await api.products.getByCategory(categoryId, take, newSkip);
        setSkip(newSkip);
        newState = newState.concat(newItems);
        setProducts(newState);
    };

    useEffect(() => {
        if (isLoading === false) {
            if (isVisible === true) {
                getMore();
            }
        }
    }, [isVisible]);

    useEffect(() => {
        getProductByCategory();
    }, [params.categoryId]);

    return (
        <>
            <head>
                <title>{CategoryDescription.replace("_", "/ /g")} product list - Growthgensolutions</title>
                <meta name="description" content={"Growthgensolutions - " + CategoryDescription.replace("_", "/ /g") + " product list"} />
            </head>

            <div className="category">
                <div className="category-header my-3">{CategoryDescription.replace("_", "/ /g")}</div>
                <div className="products">
                    {products.map((p) => (
                        <ProductShort product={p} />
                    ))}
                    <div ref={ref}></div>
                </div>
            </div>
        </>
    );
}

export default Category;
