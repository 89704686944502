import Category from "./category";
import Products from "./products";
import User from "./user";
import CustomerHippingAddress from "./customer-hipping-address";
import Order from "./order";
const api = {
    products: Products,
    category: Category,
    user: User,
    customerHippingAddress: CustomerHippingAddress,
    order: Order,
};

export const API_URI = "https://api.growthgensolutions.co.za";

function handleResponse(res: Response) {
    const contentType = res.headers.get("content-type");
    if (res.status === 401) {
    } else if (res.status === 201 || res.status === 202) {
        if (contentType) {
            if (contentType.indexOf("application/json") !== -1 || contentType.indexOf("text/plain") !== -1) {
                return res.json();
            }
        } else {
            return res;
        }
    } else if (!res.ok) {
        throw Error(res.statusText);
    } else {
        if (contentType) {
            if (contentType.indexOf("application/json") !== -1 || contentType.indexOf("text/plain") !== -1) {
                return res.json();
            }
        } else {
            return res;
        }
    }
}

export async function apiGet(url: string) {
    return fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${localStorage.AccessToken}`,
            "Content-Type": "application/json",
        },
    }).then((res) => handleResponse(res));
}

export async function apiPostAnon(url: string, body?: object) {
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => handleResponse(res));
}

export async function apiPost(url: string, body?: object) {
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${localStorage.AccessToken}`,
            "Content-Type": "application/json",
        },
    }).then((res) => handleResponse(res));
}

export async function apiPostPlain(url: string, body?: object) {
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${localStorage.AccessToken}`,
            "Content-Type": "application/json",
        },
    }).then((res) => {
        return res;
    });
}

export async function apiPut(url: string, body?: object) {
    return fetch(url, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${localStorage.AccessToken}`,
            "Content-Type": "application/json",
        },
    }).then((res) => handleResponse(res));
}

export async function apiDelete(url: string, body?: object) {
    return fetch(url, {
        method: "DELETE",
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${localStorage.AccessToken}`,
            "Content-Type": "application/json",
        },
    }).then((res) => handleResponse(res));
}

export default api;
